(function ($) {
  function change_form_label(label, input) {
    if (input.val() !== "") {
      jQuery(label).addClass("active");
    }
    jQuery(label).parent().on("click", function () {
      jQuery(input).focus();
    });
    jQuery(input).on("focus", function () {
      jQuery(label).addClass("active");
    });
    jQuery(input).on("focusout", function () {
      var value = jQuery.trim(jQuery(this).val());
      if (value.length === 0 || jQuery(this).val() === '(___) ___-____') {
        jQuery(label).removeClass("active");
      }
    });
  }
  function add_fake_button_file_upload() {
    var html = '<div class="file-upload__button__wrapper"><span class="filename">' + form_strings.file_upload_no_file_selected + '</span><span class="btn secondary">' + form_strings.file_upload_button_label + '</span></div>';
    $('.ginput_container_fileupload').each(function () {
      var container = $(this);
      var fileInput = container.find('input[type="file"]');
      fileInput.after(html);
      fileInput.change(function () {
        var text = $(this).val().replace(/C:\\fakepath\\/i, '');
        if (text === '') {
          text = form_strings.file_upload_no_file_selected;
        }
        container.find('.file-upload__button__wrapper > .filename').text(text);
      });
    });
  }
  $(document).ready(function () {
    // document ready
    change_form_label($('.sidebar__call-to-action__newsletter-form__email label'), $('.sidebar__call-to-action__newsletter-form__email input'));
    //gravity form does the same for reading in wp.a11y accessibility
    setTimeout(function () {
      if (-1 !== location.hash.indexOf('#gf_') && jQuery('.gform_anchor').length) {
        jQuery([document.documentElement, document.body]).animate({
          scrollTop: jQuery('.gform_anchor').offset().top
        }, 0);
      }
    }, 300);
    $('.gform_fileupload_multifile').click(function (e) {
      var elem = $(e.target);
      if (!elem.hasClass('gform_button_select_files')) {
        var parent = $(e.target).closest('.ginput_container_fileupload');
        parent.find('.gform_button_select_files').click();
      }
    });
  });
  jQuery(document).on('gform_post_render', function (event, form_id, current_page) {
    add_fake_button_file_upload();
    jQuery(".gfield").each(function () {
      var label = jQuery(this).find('.gfield_label');
      var input = jQuery(this).find('.ginput_container input , .ginput_container textarea');
      change_form_label(label, input);
    });
    jQuery(".gfield.field_sublabel_above .ginput_container > span").each(function () {
      var label = jQuery(this).find('label');
      var input = jQuery(this).find('input , textarea');
      change_form_label(label, input);
    });

    /*jQuery('.gfield:not(.gfield--type-checkbox):not(.gfield--type-fileupload):not(.gfield--type-select):not(.gfield--type-radio)').click(function(elem){
      console.log(jQuery(elem.currentTarget));//.find('input,textarea').click();
    });*/
  });
})(jQuery);